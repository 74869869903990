import { template as template_8f5837e4e69a4c5e834e6875ceef11e9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_8f5837e4e69a4c5e834e6875ceef11e9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
