import { template as template_81e6613d2c6b490cad90809d5a129948 } from "@ember/template-compiler";
const FKText = template_81e6613d2c6b490cad90809d5a129948(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
