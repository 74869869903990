import { template as template_082e34e82cd646ff882746b56b94db3e } from "@ember/template-compiler";
const FKLabel = template_082e34e82cd646ff882746b56b94db3e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
